.btn.btn-street-view {

  svg {
    fill: currentColor;
    margin: auto;
  }

  &.btn-square {
    font-size: 8px;
    width: 4rem;
    height: 4rem;
    padding: 0;
    margin: 0 1rem 0 60px;
    border-radius: 10%;
    text-transform: uppercase;
    background-color: #439aac;
    border-color: #d7eef5;
    color: white;

    &:hover, &.active, &:active {
      background-color: #3f91a1;
      border-color: #d7eef5;
      color: white;
    }

    img, svg {
      display: block;
      margin: auto;
    }
  }

  &.btn-back {
    img, svg {
      width: 16px;
      margin-left: .3rem;
      margin-top: -3px;
    }
  }


  &.btn-light:hover {
    background-color: white;
  }

  &.btn-light:not(:disabled):not(.disabled).active {
    background-color: var(--primary);
  }
}

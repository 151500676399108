.mappr-tutorial {
  position: absolute;
  top: 0;
  z-index: 9999;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  white-space: pre-line;

  > .row {
    height: 100vh;
  }

  &.hide {
    display: none;
  }

  .text {
    color: #fff;
    margin-top: 10px;

    p {
      font-size: 1vw;
      font-weight: bold;
    }
  }

  .layer-arrow {
    font-size: 30px;
  }

  .map-zoom {
    margin-top: calc(100vh - 90vh);
    margin-left: 20px;
    max-width: 395px;
  }

  .map-place {
    max-height: calc(100vh - 205px);
    margin: 0;
    overflow-y: auto;

    .circle {
      border-radius: 50%;
      background: rgba(225, 225, 225, 0.2);
      width: 200px;
      height: 200px;
      margin: 0 auto;

    }
  }

  .tour-layer {
    position: absolute;
    bottom: calc(100vh - 89vh);

  }

  .street-view-bottom {
    position: absolute;
    bottom: 33px;
    left: 123px;

  }

  .filter-left-right {
    margin-top: 156px;
  }

  .filter-header {
    margin-top: 57px;

    .layer-arrow {
      display: block;
      width: 100%;
      text-align: center;
    }
  }


  .poi-tour-text {

    p {
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

    }
  }

}
.MobileHeader {
  &.fixed-top {
    z-index: 1029;
  }

  .badge {
    position: absolute;
    top: -2px;
    font-size: 0.625rem;
    padding: 0.19rem .325rem;
    right: -10px;
  }

  .active {
    color: var(--primary) !important;
  }

  .logo {
    max-width: 140px;
  }
}
@import "../../../../node_modules/react-image-gallery/styles/scss/image-gallery";

.MediaGallery {
  margin: 0.5em auto;

  .modal-content {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    width: 100%;
    height: 100%;

    .modal-body {
      height: 90%;

      .image-gallery-backdrop {
        background: black;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: .6;
        margin: auto;
        @media (min-width: 768px) {
          left: 11%;
          width: 78%;
        }
      }

      .image-gallery,
      .image-gallery-content,
      .image-gallery-swipe,
      .image-gallery-slide-wrapper,
      .image-gallery-slides,
      .image-gallery-slide,
      .image-gallery-image {
        height: 100%;

        &.image-gallery-slide-wrapper {
          height: 74%;

          @media (max-height: 830px) {
            height: 86%;
          }

          @media (max-height: 750px) {
            height: 84%;
          }

          @media (max-height: 650px) {
            height: 81%;
          }

          @media (max-height: 480px) {
            height: 79%;
          }

          @media (max-height: 410px) {
            height: 74%;
          }

          @media (min-height: 831px) {
            height: 89%;
          }
        }

        &.image-gallery-image {
          display: flex;
          align-content: center;
          justify-content: center;

          img {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }

        &.image-gallery-swipe {
          @media (min-width: 768px) {
            width: 78%;
          }
          height: 100%;
          margin: auto;
        }

        &.image-gallery-slide {
          background-color: black;

          .image-gallery-description {
            bottom: 0;
          }
        }
      }

      .image-gallery-slide-wrapper {
        > .image-gallery-slides {
          @media (min-width: 768px) {
            width: 78%;
            margin: auto;
          }
        }
      }

      .image-gallery-thumbnails-wrapper {
        @media (min-width: 768px) {
          width: 78%;
        }
        height: 80px;
        margin: auto;
        padding: 10px 16px 14px;

        .image-gallery-thumbnails,
        .image-gallery-thumbnails-container,
        .image-gallery-thumbnail,
        .image-gallery-thumbnail-inner {
          padding: 0;
          border: 0;

          &.image-gallery-thumbnail {
            width: 60px;
            height: 60px;
            border: solid 2px transparent;
            border-radius: 3px;

            &.active {
              border-color: var(--primary);

              img {
                border-radius: 1px;
              }
            }

            &:not(.active) {
              opacity: 0.6;
            }
          }

          img {
            width: 56px;
            height: 56px;
            object-fit: cover;
            border-radius: 3px;
          }
        }

        .image-gallery-thumbnail + .image-gallery-thumbnail {
          margin-left: 15px;
        }
      }

      .image-gallery-thumbnails-container {
        overflow-x: auto;
        padding-bottom: 1rem !important;
      }

      .image-gallery-custom-left-nav,
      .image-gallery-custom-right-nav {
        position: absolute;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        border: 0;
        cursor: pointer;
        outline: none;
        z-index: 4;
        font-size: 1.5em;
        padding: 50px 15px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);

        &.image-gallery-custom-left-nav {
          left: 0;
        }

        &.image-gallery-custom-right-nav {
          right: 0;
        }
      }
      .pdf-wrapper {
        overflow-y: auto;

        &:not(.zoomed) {
          canvas {
            width: 100% !important;
            height: auto !important;
          }
        }

        .react-pdf__Page__textContent {
          left: 0 !important;
        }
      }
    }
  }

  &.modal-90w {
    width: 90%;
    max-width: 90%;
    margin: 0.5rem auto;
  }

  &.modal-90h {
    height: 90%;
    max-height: 90%;
  }
}

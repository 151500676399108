.DesktopHeader {
  .logo {
    border: 0.5px solid rgb(150, 150, 150);
    border-radius: 5px;
  }

  .logo-text:hover {
    color: rgb(150, 150, 150);
    text-decoration: none;
  }

  .help {
    display: flex;
    font-size: 23px;
  }

}

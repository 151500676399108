.MapLoader {

  .brand {
    font-size: 0.75rem;
    letter-spacing: 0.75rem;
  }

  .logo {
    width: 150px;
  }

}

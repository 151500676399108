.AppInfoPage {
  h5 {
    font-size: 1.25rem;

    ~div {
      text-align: center;
      img {
        max-height: 250px;
        max-width: 100%;
      }
    }
  }
}

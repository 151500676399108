.LayersMenu {
  position: absolute !important;
  bottom: 0;

  .base-maps-container {
    img {
      height: 50px;
      border-width: 2px !important;

      &:not(.border-primary) {
        border-color: transparent !important;
      }
    }
  }
  .dropdown-menu {
    @media only screen and (max-height: 800px){
      max-height: 77vh;
    }
    @media only screen and (max-height: 700px){
      max-height: 70vh;
    }
    @media only screen and (max-height: 600px) {
      max-height: 60vh;
    }
    @media only screen and (max-height: 500px) {
      max-height: 50vh;
    }
    @media only screen and (max-height: 400px) {
      max-height: 40vh;
    }
    @media only screen and (max-height: 300px) {
      max-height: 30vh;
    }
    @media only screen and (max-height: 200px) {
      max-height: 20vh;
    }
    overflow: auto;
  }
}

@font-face {
  font-family: 'TeXGyreAdventor';
  src: url('./assets/fonts/hinted-TeXGyreAdventor-Italic.eot');
  src: url('./assets/fonts/hinted-TeXGyreAdventor-Italic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Italic.woff2') format('woff2'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Italic.woff') format('woff'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Italic.ttf') format('truetype'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Italic.svg#TeXGyreAdventor-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TeXGyreAdventor';
  src: url('./assets/fonts/hinted-TeXGyreAdventor-BoldItalic.eot');
  src: url('./assets/fonts/hinted-TeXGyreAdventor-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/hinted-TeXGyreAdventor-BoldItalic.woff2') format('woff2'),
  url('./assets/fonts/hinted-TeXGyreAdventor-BoldItalic.woff') format('woff'),
  url('./assets/fonts/hinted-TeXGyreAdventor-BoldItalic.ttf') format('truetype'),
  url('./assets/fonts/hinted-TeXGyreAdventor-BoldItalic.svg#TeXGyreAdventor-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'TeXGyreAdventor';
  src: url('./assets/fonts/hinted-TeXGyreAdventor-Bold.eot');
  src: url('./assets/fonts/hinted-TeXGyreAdventor-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Bold.woff2') format('woff2'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Bold.woff') format('woff'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Bold.ttf') format('truetype'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Bold.svg#TeXGyreAdventor-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TeXGyreAdventor';
  src: url('./assets/fonts/hinted-TeXGyreAdventor-Regular.eot');
  src: url('./assets/fonts/hinted-TeXGyreAdventor-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Regular.woff2') format('woff2'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Regular.woff') format('woff'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Regular.ttf') format('truetype'),
  url('./assets/fonts/hinted-TeXGyreAdventor-Regular.svg#TeXGyreAdventor-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DancingScriptOT';
  src: url('./assets/fonts/hinted-DancingScriptOT.eot');
  src: url('./assets/fonts/hinted-DancingScriptOT.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/hinted-DancingScriptOT.woff2') format('woff2'),
  url('./assets/fonts/hinted-DancingScriptOT.woff') format('woff'),
  url('./assets/fonts/hinted-DancingScriptOT.ttf') format('truetype'),
  url('./assets/fonts/hinted-DancingScriptOT.svg#DancingScriptOT') format('svg');
  font-weight: normal;
  font-style: normal;
}

html, body, #root {
  margin: 0;
  height: 100%;
  user-select: none;
  color: #212529;
}

.clearfix {
  clear: both;
}

.col-0 {
  width: 0;
}

//:global {
    .no-chevron {
      &:after {
        content: '' !important;
        border: none;
        margin: 0;
        display: none !important;
      }
    }

    .POIPrintPreview {
      display: none;
    }

    .printing-active {
      .POIPrintPreview {
        display: block;
      }

      .container {
        width: auto;
      }
    }

    button {
      svg {
        fill: currentColor;
      }
    }

    .url-container {
      word-break: break-all;
      white-space: normal;
    }
  //}

  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    background-color: #a5a5a5 !important;
    box-shadow: inset 0 3px 5px transparentize(rgb(0, 0, 0), .70) !important;
  }
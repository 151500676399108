.POIPrintPreview {
  .card {
    .icon-container {
      width: 3.5rem;
      height: 3.5rem;

      .icon {
        font-size: 1.5rem !important;
        line-height: 2.3;

      }
    }

    .card-image-container {
      height: 30vh;
      img {
        height: 100%;
        width: 100vw;
        object-fit: contain;
      }
    }
  }
}

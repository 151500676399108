.card {
  &.card-compact .card-img-top {
    height: 55%;
  }

  h5 {
    .icon {
      font-size: 1.5rem;
    }
  }
}

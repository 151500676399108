.MobileLayout {
  height: 100%;

  .page-active {
    z-index: 1028;

    &:last-child {
      z-index: 1031;
    }
  }

  .page-hidden {
    z-index: -10;
  }

  .mapboxgl-canvas-container {
    height: 100%;
  }

  > .container-fluid {
    overflow: hidden;
    padding-top: 3.5rem;
  }

  .custom-autocomplete {
    max-height: 20rem;
    overflow: scroll;
    position: absolute;
    width: 100%;
  }

  .filter-dropdown {
    max-height: 380px;
    max-width: 300px;
    overflow: auto;
  }

  .pagination-content {
    padding: 0.22rem 0.5rem 0.2rem;
  }

  .ListHeaderContents {
    border-bottom: solid 1px #e6e6e6;

    .resultsCount {
      line-height: 1.625;
    }
  }

  .MapPopup {
    transition: max-height 0.3s ease-out;
    //max-height: 42%;

    &.collapsed {
      max-height: 0;
    }

    .list-group {
      overflow-y: auto;
      overflow-x: hidden;

      .media, .media:hover {
        background-color: transparent;
        border-color: #e6e6e6;

        .media-body {
          h5 {
            max-height: 3rem;
            line-height: 1.2;
            color: black;
          }

          p {
            line-height: 1.2;
            max-height: 2rem;
          }
        }

        h5, p {
          &:after {
            background-color: white;
          }
        }
      }

      .more {
        white-space: nowrap;
      }
    }
  }

  .POIPage {
    > .container-fluid {
      overflow: hidden;
      //padding-top: 3.5rem;
    }
  }
}

.MediaGallery.full-screen {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  margin: 0 !important;

  .modal-header {
    position: absolute;
    width: 100%;
    z-index: 1;
    padding: 0.5rem !important;
    background-color: rgba(0, 0, 0, .2);
  }
}

.App .MobileLayout {
  .mapboxgl-popup {
    min-width: 160px !important;
  }

  .mapboxgl-popup-content {
    min-width: 160px;
    max-width: 200px;
    padding: 0;

    .MapPopup {
      .media, .media:hover {
        background-color: transparent;
        border-color: #e6e6e6;

        .media-body {
          h5 {
            max-height: 3rem;
            line-height: 1;
            color: black;
            font-size: 0.8rem;
            margin-bottom: 0 !important;

          }

          p {
            line-height: 1;
            max-height: 2rem;
          }
        }

      }

    }
  }

  .card-text {
    .react-expand-collapse__button {
      background-image: linear-gradient(to bottom, rgba(248, 248, 250, 0.4), #f8f9fa);

      button {
        background-image: linear-gradient(to bottom, rgba(248, 248, 250, 0.4), #f8f9fa);
      }
    }
  }
}

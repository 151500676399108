.OldBrowserMessage {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;

  img {
    display: inline-block;
  }

  .message-container {
    width: 50%;
    margin-top: 15%;
    margin-left: auto;
    margin-right: auto;

    .logo-container {
      text-align: center;
      width: 100%;
      margin-bottom: 40px;
      img {
        width: 100px;
      }
    }

    .footer {
      margin-top: 20px;
      width: 100%;
      text-align: center;

      .icon {
        img {
          width: 50px;
          height: 50px;
          margin-right: 20px;
        }
      }
    }
  }
}

.App {
  height: 100%;

  .btn:focus, .btn-light:focus, .btn-light:not(:disabled):not(.disabled).active:focus {
    outline: none;
    box-shadow: none;
  }

  .img-contain {
    object-fit: contain !important;
  }

  .img-cover {
    object-fit: cover;
  }

  .nav-filters .nav-link {
    border: 0;
  }

  .POIPage {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1029 !important;
    overflow: auto;

    a {
      text-decoration: underline;
    }
    .card-img-overlay {
      h4 {
        // text-overflow: ellipsis;
        max-height: 4.5rem;
        overflow: hidden;
      }
    }

    .card-text, .card-title {
      user-select: text;
    }

    .card-image-container {
      .bg-primary-transparent {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    &.fullscreen {
      top: 60px;
      height: calc(100% - 60px);

      .card {
        .card-image-container {
          position: relative;
          height: 30vh;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &:not(.fullscreen) {
      .fixed-top + div {
        .poi-heading {
          margin-top: 56px;
        }
      }

      .poi-heading {
        padding-top: 45%;

        .card-image-container {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .card {
      background-color: transparent;

      .icon-container {
        width: 3.5rem;
        height: 3.5rem;
        align-self: flex-end;

        .icon, .ic {
          font-size: 1.5rem !important;
          line-height: 2.3;
        }
      }

      &:hover,
      &:active,
      &.active {
        background-color: white;
      }
    }

    .galleries {
      .card {
        padding-top: 27.5% !important;

        .card-img, .card-img-overlay {
          position: absolute;
          top: 1rem;
          left: 1rem;
          bottom: 1rem;
          right: 1rem;
          width: calc(100% - 1.5rem);
          object-fit: cover;
          height: calc(100% - 1rem);
        }

        @media (min-width: 768px) {
          &:nth-child(2n) {
            .card-img, .card-img-overlay {
              right: 1rem;
              left: auto;
            }
          }

          &:nth-child(2n + 1) {
            .card-img, .card-img-overlay {
              left: 1rem;
              right: auto;
            }
          }
        }
      }
    }
  }

  .mapboxgl-ctrl-geocoder {
    display: none;
  }

  .mapbox-improve-map {
    display: none;
  }

  .mapbox-improve-map, a[href="https://www.mapbox.com/map-feedback/"] {
    display: none;
  }

  .mapboxgl-ctrl-bottom-right {
    .mapboxgl-ctrl {
      margin-right: 20px;

      &.mapboxgl-compact {
        margin-right: 25px;
        margin-top: 15px;
      }

      &.custom-map-control {
        > div {
          width: 34px;
          height: 34px;
          box-shadow: none;
          border: solid 1px rgba(0, 0, 0, 0.1);

          .bg-overlay {
            width: 34px;
            height: 34px;
            padding-right: 2px;
          }
        }
      }

      .mapboxgl-ctrl-icon {
        &.mapboxgl-ctrl-zoom-in {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:%23666666;' d='M 10 6 C 9.446 6 9 6.4459904 9 7 L 9 9 L 7 9 C 6.446 9 6 9.446 6 10 C 6 10.554 6.446 11 7 11 L 9 11 L 9 13 C 9 13.55401 9.446 14 10 14 C 10.554 14 11 13.55401 11 13 L 11 11 L 13 11 C 13.554 11 14 10.554 14 10 C 14 9.446 13.554 9 13 9 L 11 9 L 11 7 C 11 6.4459904 10.554 6 10 6 z'/%3E %3C/svg%3E")
        }

        &.mapboxgl-ctrl-zoom-out {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath style='fill:%23666666;' d='m 7,9 c -0.554,0 -1,0.446 -1,1 0,0.554 0.446,1 1,1 l 6,0 c 0.554,0 1,-0.446 1,-1 0,-0.554 -0.446,-1 -1,-1 z'/%3E %3C/svg%3E");
        }

        &.mapboxgl-ctrl-geolocate:not([class*="mapboxgl-ctrl-geolocate-"]) {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23666'%3E %3Cpath d='M10 4C9 4 9 5 9 5L9 5.1A5 5 0 0 0 5.1 9L5 9C5 9 4 9 4 10 4 11 5 11 5 11L5.1 11A5 5 0 0 0 9 14.9L9 15C9 15 9 16 10 16 11 16 11 15 11 15L11 14.9A5 5 0 0 0 14.9 11L15 11C15 11 16 11 16 10 16 9 15 9 15 9L14.9 9A5 5 0 0 0 11 5.1L11 5C11 5 11 4 10 4zM10 6.5A3.5 3.5 0 0 1 13.5 10 3.5 3.5 0 0 1 10 13.5 3.5 3.5 0 0 1 6.5 10 3.5 3.5 0 0 1 10 6.5zM10 8.3A1.8 1.8 0 0 0 8.3 10 1.8 1.8 0 0 0 10 11.8 1.8 1.8 0 0 0 11.8 10 1.8 1.8 0 0 0 10 8.3z'/%3E %3C/svg%3E");
        }
      }
    }
  }

  .mapboxgl-ctrl-group {
    border-radius: 16px;
    border: solid 1px rgba(0, 0, 0, 0.1);

    &:not(:empty) {
      box-shadow: none;
    }

    > button {
      height: 32px;
      width: 32px;

      + button {
        border-top: none;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: calc(50% - 10px);
          top: -1px;
          height: 1px;
          width: 20px;
          border-bottom: 1px solid #e6e6e6;
        }

      }
    }
  }

  .mapboxgl-popup {
    min-width: 300px !important;
  }

  .mapboxgl-popup-content {
    min-width: 300px;
    max-width: 375px;
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.24), 0 40px 77px 0 rgba(0, 0, 0, 0.19);

    .close {
      width: 14px;
      height: 14px;
      font-size: .875rem;
    }

    .MapPopup {
      .media, .media:hover {
        background-color: transparent;
        border-color: #e6e6e6;

        .media-body {
          h5 {
            max-height: 3rem;
            line-height: 1.2;
            color: black;
          }

          p {
            line-height: 1.2;
            max-height: 2rem;
          }
        }

        h5, p {
          &:after {
            background-color: white;
          }
        }
      }

      &.cluster {
        .media:hover {
          cursor: pointer;
        }
      }
    }
  }

  .mapboxgl-popup-close-button {
    text-indent: -100%;
    white-space: nowrap;
    overflow: hidden;
    z-index: 20;
    width: 30px;
    height: 30px;

    &::after {
      font-family: 'icomoon', serif !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      content: "\e91f";
      width: 20px;
      height: 20px;
      color: black;
      font-size: 1rem;
      display: inline-block;
      text-indent: 0;
      padding-top: .5rem;
    }
  }

  .react-expand-collapse__content {
    position: relative;

    &:not(.react-expand-collapse--expanded) {
      overflow: hidden;
    }

    .react-expand-collapse__button {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      text-align: right;

      button {
        width: 200px;
        background-image: linear-gradient(to right, rgba(252, 252, 252, 0), var(--light));
      }
    }

    &.react-expand-collapse--expanded {
      .react-expand-collapse__button {
        position: relative;
        bottom: auto;
        right: auto;

        &::before {
          content: none;
        }
      }
    }
  }

  .card-text {
    .react-expand-collapse__button {
      left: 0;
      bottom: 0;
      top: auto;
      text-align: right;
      display: block;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), #ffffff);
      padding-top: .8rem;

      button {
        width: 100%;
        background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.4), #ffffff);
      }
    }
  }

  &[data-layout="5"] {
    .ResultsCountContainer.has-data + #map-container {
      .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-bottom-left {
        bottom: 25%;
      }
    }
  }

  &[data-theme="dark"] {
    .react-expand-collapse__button {
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      text-align: right;

      button {
        width: 200px;
        color: #ffffff;
        background-image: linear-gradient(to right, rgba(252, 252, 252, 0), var(--primary));
      }
    }
  }

  .dropdown-menu-right {
    right: 0 !important;
    left: auto !important;
  }
}

.date-range {
  .dropdown-item, .dropdown-menu {
    padding: 0.3rem;
  }

  .CalendarDay__selected_span {
    background: var(--primary);
    color: white; //text
    border: 1px solid var(--primary);
  }

  .CalendarDay__selected {
    background: var(--primary);
    border: var(--primary);
    color: white;
  }


  .CalendarDay__selected:hover {
    background: var(--primary);
    color: white;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: var(--primary);
    border: var(--primary);
    color: white;
  }

  .DateInput_input__focused {
    border-bottom-color: var(--primary);
  }

  .DateRangePickerInput__withBorder {
    border: none;
  }

  .clear {
    font-size: 15px;
    padding-left: 0.5rem;
    color: #f7b914;
    text-decoration: underline;
  }

  .dropdown-item {
    white-space: pre !important;
  }
}

.number-range-filter {

  @media (min-width: 768px) {
    min-width: 17rem !important;
  }

  .dropdown-item {
    padding: 0 2rem;
    font-size: 12px;

    &:active {
      color: #000 !important;
    }

    .range-min-max {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .line {
        height: 36px;
        display: flex;
        width: 90%;

        > div {
          height: 5px;
          width: 100%;
          border-radius: 4px;
          align-self: center;
        }
      }

      .points {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        background-color: var(--primary);
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 6px #aaa;
      }
    }

    .apply-clear {
      display: flex;
      justify-content: space-between;

      div {
        text-decoration: underline;

        &:nth-child(1) {
          margin-right: 15px;
        }

        &:hover {
          cursor: pointer;
          color: var(--primary);
        }
      }
    }
  }
}

.fit-content {
  width: fit-content !important;
}


.marker-label {
  width: fit-content;
  height: fit-content;
  background-color: black;
  color: white;
  padding: 0 0.5em;
  margin: -1.2em auto;
  border-radius: 0.5em;
  font-weight: bold;
  display: flex;
  justify-content: center;
}
.marker-label span {
  font-size: 0.9em;
  //line-height: 1.6em;
  //padding: 2px;
}
.marker-label:after {
  content: "";
  height: 0;
  width: 0;
  border-top: 0.4em solid #000;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  position: absolute;
  bottom: -0.3em;
}

[data-mode="desktop"], [data-mode="tablet"] {
  $sidebar-animation-speed: 200ms;

  section {
    >[class*="col-"] {
      transition: all $sidebar-animation-speed ease-in-out;
    }

  //   >.col-12 {
  //     flex-grow: 1;

  //     &.left {
  //       margin-right: -33.333333%;
  //       .mapboxgl-control-container {
  //         > [class*=right] {
  //           padding-right: 33.333333%;
  //           transition: padding $sidebar-animation-speed ease-in-out;
  //         }
  //       }
  //     }

  //     &.right {
  //       margin-left: -33.333333%;

  //       .mapboxgl-control-container {
  //         > [class*=left] {
  //           padding-left: 33.333333%;
  //           transition: padding $sidebar-animation-speed ease-in-out;
  //         }
  //       }
  //     }

  //     &.expanded {
  //       margin-right: 0;
  //       margin-left: 0;

  //       &.left {
  //         .mapboxgl-control-container {
  //           > [class*=right] {
  //             padding-right: 0;
  //           }
  //         }
  //       }

  //       &.right {
  //         .mapboxgl-control-container {
  //           > [class*=left] {
  //             padding-left: 0;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   >.col-0 {
  //     // width: 0;
  //     flex-shrink: 1;
  //     flex-basis: 0;
  //     border: 0 !important;
  //   }
  }

    aside {
      &.collapsed {
        .POIPage {
          overflow: hidden;
          left: initial;
          right: initial;
          top: initial;
          width: 0;
        }
      }
      .text-muted {
        color: #9b9b9b !important;
      }
    }

    .sidebar-toggle-container {
      z-index: 1033;

      &[aria-expanded='true'].expanded {
        border-width: 0;

        .sidebar-toggle:after {
          content: '\e906';
          bottom: 0;
        }
      }

      .sidebar-toggle:after {
        font-family: "icomoon", serif;
        display: block;
        width: 1.25rem;
        position: absolute;
        bottom: calc(50% - 1.625rem);
        color: white;
        text-align: center;
        padding: 15px 0px;
        background: var(--primary);
      }

      &.right-sidebar {
        right: 0;

        > .sidebar-toggle {
          &:after {
            content: '\e902';
            right: 0;
            border-radius: 0.25rem 0 0 0.25rem;
          }
        }
      }

      &.left-sidebar {
        left: 0;
        .sidebar-toggle{
          &:after {
            content: '\e903';
            border-radius: 0 0.25rem 0.25rem 0;
          }
        }
      }
    }


    .MapPopup {
      max-height: 300px;
      overflow: auto;
    }
  }

  .filter-dropdown {
    &.scrollable {
      max-height: 400px;
      overflow: auto;
    }
  }

.measurement{
  position: absolute;
  bottom: 62px;
  right: 67px;
  width: 13rem;
  height: 95px;
  background: #fff;
  padding:0.5rem;
  border-width: 2px;
  border-style: solid ;
}

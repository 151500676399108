.PageIndicators {
  align-items: center;

  > li {
    width: 10px;
    height: 10px;
    background-color: #d8d8d8;
    border-radius: 10px;
    border: none;

    &.active {
      width: 14px;
      height: 14px;
      border-radius: 14px;
      background-color: var(--primary);
    }
  }
}
